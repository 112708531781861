<!--
 * @Descripttion:
 * @version:
 * @Author: 李宗哲
 * @Date: 2020-04-15 11:08:52
 * @LastEditors: 王广婷
 * @LastEditTime: 2020-05-27 19:26:03
 -->
<template>
  <div class="concent" :style="(datalist.length == '0')?'background-color: #ffffff;':''">
    <div class="team-ren" v-for="(item,index) in datalist" :key="index" @click="seeteam(item)">
      <div class="ahead">
        <span class="team-name">{{item.user.empname}}</span>
        <span class="team-empno">{{item.user.empno}}</span>
      </div>
      <div class="situation">
        <span class="left">加入时间</span>
        <span class="right">{{item.user.indate&&item.user.indate=='undefined'?'':item.user.indate}}</span>
      </div>
      <div class="situation">
        <span class="left">累计订单数量</span>
        <span class="right">{{item.ddnum}}个</span>
      </div>
      <div class="situation">
        <span class="left">累计出单保费</span>
        <span class="right">{{item.mount}}元</span>
      </div>
      <div class="situation">
        <span class="left">累计贡献推荐奖励</span>
        <span class="rmb">{{item.tjmount}}元</span>
      </div>
    </div>
    <div style="margin-top: 20vh;">
      <img src="@/assets/nh/nhwxtdnull.jpg" v-if="datalist.length == '0'" />
    </div>
  </div>
</template>

<script>
import { Listteam } from '@/api/agent/agent'
import { getStorage } from '@/lib/util'
export default {
  components: {},
  data () {
    return {
      user: '',
      datalist: [],
      teamuser: ''
    }
  },
  created () {
    this.user = getStorage('u_s', {})
    this.getteam()
  },
  methods: {
    seeteam (val) {
      this.$router.push(
        `/personaldetails?empname=${val.user.empname}&empno=${val.user.empno}&indate=${val.user.indate}&ddnum=${val.ddnum}&mount=${val.mount}&tjmount=${val.tjmount}`
      )
    },
    getteam () {
      Listteam({ empno: this.user.empno }).then(res => {
        this.datalist = res.data.data
      })
    }
  }
}
</script>

<style scoped>
.concent {
  height: 100vh;
  overflow: auto;
  padding-bottom: 1.5rem;
  background-color: #ffffff;
}
.team-ren {
  padding: 0.4rem 0.4rem;
  background-color: #ffffff;
  box-shadow: 0.02rem 0.02rem 0.4rem #f6f6f6;
  width: 90%;
  margin: 0.5rem auto 0 auto;
}
.ahead {
  display: flex;
  justify-content: space-between;
  height: 1rem;
  line-height: 1rem;
  border-bottom: 0.01rem solid rgb(240, 240, 240);
  margin-bottom: 0.2rem;
}
.team-name {
  color: #383838;
  font-size: 0.39rem;
  font-family: PingFang-SC-Bold;
  font-weight: bold;
  margin-left: 0.3rem;
}
.team-empno {
  color: #383838;
  font-size: 0.39rem;
  font-family: PingFang-SC-Bold;
  font-weight: bold;
  margin-right: 0.3rem;
}
.situation {
  display: flex;
  justify-content: space-between;
  height: 1rem;
  line-height: 1rem;
}
.left {
  font-family: PingFang-SC-Medium;
  color: #9b9b9b;
  font-size: 0.36rem;
  margin-left: 0.3rem;
}
.right {
  font-family: PingFang-SC-Medium;
  color: #9b9b9b;
  font-size: 0.36rem;
  margin-right: 0.3rem;
}
.rmb {
  font-family: PingFang-SC-Medium;
  color: #fb6821;
  font-size: 0.36rem;
  margin-right: 0.3rem;
}
</style>
